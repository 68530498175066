// ======= Start > Permissions ======= //
// Boooking Center
export const READ_URGENT_BOOKING_LIST = 'R:BgUgLt';
export const READ_NON_INSTANT_BOOKING_LIST = 'R:BgNiLt';
export const READ_ISSUED_BOOKING_LIST = 'R:BgIsLt';
export const CREATE_BULK_REISSUE = 'C:BlBgRs';

// Booking Detail
export const READ_BOOKING_DETAIL = 'R:BkgDtl';
export const READ_CHECK_BOOKING_STATUS = 'R:BkgSts';
export const READ_BOOKING_INVOICE = 'R:BkgInv';
export const READ_BOOKING_VOUCHER = 'R:BkgVcr';
export const READ_BOOKING_INFORMATION = 'r:bkgInfo';
export const READ_PAYMENT_INFORMATION = 'r:pymntInfo';
export const READ_BOOKING_OTHERS = 'r:bkgOth';
export const READ_BOOKING_NOTES = 'r:bkgNts';
export const READ_CUSTOMER_INFORMATION = 'r:custInfo';
export const UPDATE_BOOKING_ISSUED = 'U:BkgIsd';
export const CREATE_SEND_COMMUNICATION = 'C:SndCmt';
export const CREATE_REISSUE = 'C:BkgRse';
export const CREATE_REFUND = 'C:BkgRfd';
export const CREATE_UPLOAD_VOUCHER = 'C:BkgVcr';
export const RESCHEDULE_BOOKING = 'R:RschdlBkng';
export const READ_ACTIVITY_LOGGING = 'r:actlog';
export const READ_ACTIVITY_LOGGING_PARTITION = 'r:actlogPart';
export const EXECUTE_ACTIVITY_LOGGING_PARTITION = 'e:actlogBatch';

// Routing Dashboard
export const READ_ROUTE_DATA = 'R:RutDt';
export const READ_ROUTE_LIST = 'R:RutLst';
export const READ_ACTIVE_ROUTE_LIST = 'R:ActRut';
export const READ_ROUTE_PRODUCT_AUTOCOMPLETE = 'R:RtPdAc';
export const READ_ROUTE_GEO_AUTOCOMPLETE = 'R:RtGeAc';
export const READ_PROVIDER_LIST = 'D:RtPvLt';
export const CREATE_ROUTE = 'C:RutDt';
export const UPDATE_ROUTE = 'U:RutDt';

// Content Management
export const READ_STATIC_DATA_SUMMARY = 'R:SDSum';
export const READ_STATIC_DATA_ATTRIBUTE = 'R:SDAtt';
export const READ_STATIC_DATA_LOCATION = 'R:SDLoc';
export const READ_STATIC_DATA_ASSET = 'R:SDAss';
export const READ_STATIC_DATA_REVIEW = 'R:SDRev';
export const READ_STATIC_DATA_AVAILABLE_LANGUAGE = 'R:SDAbLg';
export const READ_STATIC_DATA_PRODUCT_LIST = 'R:SDPdAc';

export const UPDATE_STATIC_DATA_SUMMARY = 'U:SDSum';
export const UPDATE_STATIC_DATA_ATTRIBUTE = 'U:SDAtt';
export const UPDATE_STATIC_DATA_LOCATION = 'U:SDLoc';
export const UPDATE_STATIC_DATA_ASSET = 'U:SDAss';
export const DELETE_STATIC_DATA_ASSET = 'D:SDAss';

// Landmark Mapping
export const READ_LANDMARK_MAPPING_LIST = 'R:LmkLs';
export const READ_LANDMARK_AUTOCOMPLETE = 'R:LmkAc';
export const READ_LANDMARK_PRODUCT_AUTOCOMPLETE = 'R:LmkPdAc';
export const CREATE_LANDMARK_MAPPING = 'C:LmkMap';
export const DELETE_LANDMARK_MAPPING = 'D:LmkMap';

// BMG Whitelist
export const UPDATE_BMG_WHITELIST = 'U:BMGWhlst';
export const DELETE_BMG_WHITELIST = 'D:BMGWhlst';

// POT Whitelist
export const UPDATE_POT_WHITELIST = 'U:POTWhlst';
export const DELETE_POT_WHITELIST = 'D:POTWhlst';

// Eats Blacklist
export const UPDATE_EATS_BLACKLIST = 'U:EatsBllst';
export const DELETE_EATS_BLACKLIST = 'D:EatsBllst';

// Product Mapping
export const READ_EXP_PRODUCT_MAPPING = 'R:SeoPrdtRedi';
export const CREATE_EXP_PRODUCT_MAPPING = 'C:SeoPrdtRedi';

// Activate / Deactivate
export const UPDATE_EXP_PRODUCT = 'U:ExpPrdct';

// Cache
export const FLUSH_CACHE = 'U:PrdChc';

// Single Job Import
export const SINGLE_JOB_IMPORT = 'C:SngJbImp';

// Hidden Product
export const READ_HIDDEN_PRODUCT = 'R:HddnPrdt';
export const CREATE_HIDDEN_PRODUCT = 'C:HddnPrdt';
export const DELETE_HIDDEN_PRODUCT = 'D:HddnPrdt';

// Tag Mapping
export const READ_TAG_MAPPING = 'R:TgMap';
export const CREATE_TAG_MAPPING = 'C:TgMap';
export const DELETE_TAG_MAPPING = 'D:TgMap';

// Searchable Item
export const UPDATE_SEARCHABLE_ITEM = 'U:SrchblItm';
export const DELETE_SEARCHABLE_ITEM = 'D:SrchblItm';

// Active Items
export const READ_ACTIVE_EXPERIENCE_LIST = 'R:AcExLt';
export const READ_ACTIVE_GEO_LIST = 'R:AcGeLt';

// Alternate Geo Location
export const READ_ALTERNATE_GEO_LOCATION_LIST = 'R:AglLst';
export const UPDATE_ALTERNATE_GEO_LOCATION_ITEM = 'U:AglLst';
export const DELETE_ALTERNATE_GEO_LOCATION_ITEM = 'D:AglLst';

// Review Moderation
const READ_REVIEW_LIST_UGC = 'R:RvwLst';
const CREATE_REVIEW_MODERATION_UGC = 'c:rvwMd';
const READ_REVIEW_MODERATION_UGC = 'r:rvwMd';
const UPDATE_REVIEW_MODERATION_UGC = 'u:rvwMd';
const DELETE_REVIEW_MODERATION_UGC = 'd:rvwMd';
const READ_REVIEW_MODERATION_STATISTIC_UGC = 'r:rvmMdStat';
export const REVIEW_MODERATION_COLLECTION = [
  READ_REVIEW_LIST_UGC,
  CREATE_REVIEW_MODERATION_UGC,
  DELETE_REVIEW_MODERATION_UGC,
  READ_REVIEW_MODERATION_UGC,
  UPDATE_REVIEW_MODERATION_UGC,
  READ_REVIEW_MODERATION_STATISTIC_UGC,
];

// Static Page
export const LIST_STATIC_PAGE_KEYWORD = 'R:StPgKw';
export const GENERATE_STATIC_PAGE_SITEMAP = 'U:GnSpSm';

// Contingency Banner
export const LIST_CONTINGENCY_BANNER = 'R:LstCnBn';
export const CREATE_CONTINGENCY_BANNER = 'C:CrtCnBn';
export const UPDATE_CONTINGENCY_BANNER = 'U:UptCnBn';
export const DELETE_CONTINGENCY_BANNER = 'D:DltCnBn';

// Quick Filter
export const READ_QUICK_FILTER = 'R:QckFltr';
export const CREATE_QUICK_FILTER = 'C:QckFltr';
export const UPDATE_QUICK_FILTER = 'U:QckFltr';
export const DELETE_QUICK_FILTER = 'D:QckFltr';

// Google TTD Product Feed
export const READ_GOOGLE_TTD_PRODUCT_FEED = 'R:GTTDPdFd';
export const UPDATE_GOOGLE_TTD_PRODUCT_FEED = 'U:GTTDPdFd';
export const DELETE_GOOGLE_TTD_PRODUCT_FEED = 'D:GTTDPdFd';

// Rule Platform
export const VIEW_RULE_DISPLAY_PLATFORM = 'v:rpdisp';
export const EDIT_RULE_DISPLAY_PLATFORM = 'e:rpdisp';

// Ticket Filter
export const READ_TICKET_FILTER = 'R:TcktFltr';
export const UPSERT_TICKET_FILTER = 'U:TcktFltr';
export const DELETE_TICKET_FILTER = 'D:TcktFltr';

// Landing Page Menu Config
export const READ_LANDING_PAGE_MENU_CONFIG_LIST = 'R:LndPgMnCfgLst';
export const READ_LANDING_PAGE_MENU_CONFIG_DETAIL = 'R:LndPgMnCfgDtl';
export const CREATE_LANDING_PAGE_MENU_CONFIG = 'C:LndPgMnCfg';
export const UPDATE_LANDING_PAGE_MENU_CONFIG = 'U:LndPgMnCfg';
export const DELETE_LANDING_PAGE_MENU_CONFIG = 'D:LndPgMnCfg';

// Pride Tools
export const VIEW_PRIDE_CONFIG_LIST = 'R:CpnCnfg';
export const REPLACE_PRIDE_CONFIG = 'U:CpnCnfg';
export const DELETE_PRIDE_CONFIG = 'D:CpnCnfg';

// Access Code Tools
export const VIEW_ACCESS_CODE_LIST = 'R:AcsCdCnf';
export const CREATE_ACCESS_CODE = 'C:AcsCdCnf';
export const DELETE_ACCESS_CODE = 'D:AcsCdCnf';
export const UPDATE_ACCESS_CODE = 'U:AcsCdCnf';

// Product Purchase Config
export const READ_PRODUCT_PURCHASE_CONFIG_LIST = 'R:PrdPchCfgLst';
export const READ_PRODUCT_PURCHASE_CONFIG_DETAIL = 'R:PrdPchCfgDtl';
export const CREATE_PRODUCT_PURCHASE_CONFIG = 'C:PrdPchCfg';
export const UPDATE_PRODUCT_PURCHASE_CONFIG = 'U:PrdPchCfg';
export const DELETE_PRODUCT_PURCHASE_CONFIG = 'D:PrdPchCfg';

// Virtual Waiting Room Config
export const READ_VIRTUAL_WAITING_ROOM_CONFIG = 'R:VWRCnf';
export const CREATE_VIRTUAL_WAITING_ROOM_CONFIG = 'C:VWRCnf';
export const UPDATE_VIRTUAL_WAITING_ROOM_CONFIG = 'U:VWRCnf';
export const DELETE_VIRTUAL_WAITING_ROOM_CONFIG = 'D:VWRCnf';

// ======= End > Permissions ======= //

// ======= Start > Group Permissions ======= //
export const BOOKING_CENTER_PAGE = [READ_URGENT_BOOKING_LIST, READ_ISSUED_BOOKING_LIST];
export const ROUTING_CENTER_PAGE = [READ_ROUTE_LIST, READ_ACTIVE_ROUTE_LIST];
export const ROUTING_DETAIL_PAGE = [
  READ_ROUTE_DATA,
  READ_ROUTE_GEO_AUTOCOMPLETE,
  READ_ROUTE_PRODUCT_AUTOCOMPLETE,
  READ_PROVIDER_LIST,
];
export const CONTENT_DETAIL = [
  READ_STATIC_DATA_ASSET,
  READ_STATIC_DATA_ATTRIBUTE,
  READ_STATIC_DATA_LOCATION,
  READ_STATIC_DATA_REVIEW,
  READ_STATIC_DATA_SUMMARY,
];
export const LANDMARK_MAPPING_PAGE = [
  READ_LANDMARK_MAPPING_LIST,
  READ_LANDMARK_AUTOCOMPLETE,
  READ_LANDMARK_PRODUCT_AUTOCOMPLETE,
  CREATE_LANDMARK_MAPPING,
  DELETE_LANDMARK_MAPPING,
];
export const BMG_WHITELIST = [UPDATE_BMG_WHITELIST, DELETE_BMG_WHITELIST];
export const POT_WHITELIST = [UPDATE_POT_WHITELIST, DELETE_POT_WHITELIST];
export const EATS_BLACKLIST = [UPDATE_EATS_BLACKLIST, DELETE_EATS_BLACKLIST];
export const PRODUCT_MAPPING_PAGE = [READ_EXP_PRODUCT_MAPPING, CREATE_EXP_PRODUCT_MAPPING];
export const TAG_MAPPING = [READ_TAG_MAPPING, CREATE_TAG_MAPPING, DELETE_TAG_MAPPING];
export const SEARCHABLE_ITEM = [UPDATE_SEARCHABLE_ITEM, DELETE_SEARCHABLE_ITEM];
export const ACTIVE_EXP_GEO = [READ_ACTIVE_EXPERIENCE_LIST, READ_ACTIVE_GEO_LIST];
export const ALTERNATE_GEO_LOCATION = [READ_ALTERNATE_GEO_LOCATION_LIST];
export const CONTINGENCY_BANNER = [LIST_CONTINGENCY_BANNER];
export const QUICK_FILTER = [READ_QUICK_FILTER];
export const HIDDEN_PRODUCT = [READ_HIDDEN_PRODUCT, CREATE_HIDDEN_PRODUCT, DELETE_HIDDEN_PRODUCT];
export const RULE_DISPLAY_PLATFORM = [VIEW_RULE_DISPLAY_PLATFORM, EDIT_RULE_DISPLAY_PLATFORM];
export const LANDING_PAGE_MENU_CONFIG = [
  CREATE_LANDING_PAGE_MENU_CONFIG,
  READ_LANDING_PAGE_MENU_CONFIG_LIST,
  READ_LANDING_PAGE_MENU_CONFIG_DETAIL,
  UPDATE_LANDING_PAGE_MENU_CONFIG,
  DELETE_LANDING_PAGE_MENU_CONFIG,
];
export const PRIDE_TOOLS = [VIEW_PRIDE_CONFIG_LIST, REPLACE_PRIDE_CONFIG, DELETE_PRIDE_CONFIG];
export const ACCESS_CODE_TOOL = [VIEW_ACCESS_CODE_LIST, CREATE_ACCESS_CODE, UPDATE_ACCESS_CODE, DELETE_ACCESS_CODE];
export const PRODUCT_PURCHASE_CONFIG = [
  READ_PRODUCT_PURCHASE_CONFIG_LIST,
  READ_PRODUCT_PURCHASE_CONFIG_DETAIL,
  CREATE_PRODUCT_PURCHASE_CONFIG,
  UPDATE_PRODUCT_PURCHASE_CONFIG,
  DELETE_PRODUCT_PURCHASE_CONFIG,
];
export const VIRTUAL_WAITING_ROOM_CONFIG = [
  READ_VIRTUAL_WAITING_ROOM_CONFIG,
  CREATE_VIRTUAL_WAITING_ROOM_CONFIG,
  UPDATE_VIRTUAL_WAITING_ROOM_CONFIG,
  DELETE_VIRTUAL_WAITING_ROOM_CONFIG,
];
// ======= End > Group Permissions ======= //

// ======= Start > To Be Removed (Deprecations) ======= //
export const READ_REVIEW_AVAILABLE_LANGUAGE = 'R:RvAbLg';
export const READ_REVIEW_COUNT = 'R:RvwCnt';
export const READ_REVIEW_LIST = 'R:RvwLst';
export const READ_REVIEW_PRODUCT_AUTOCOMPLETE = 'R:RvPdAc';
export const REVIEW_MODERATION_PAGE = [
  READ_REVIEW_AVAILABLE_LANGUAGE,
  READ_REVIEW_COUNT,
  READ_REVIEW_LIST,
  READ_REVIEW_PRODUCT_AUTOCOMPLETE,
];
// ======= End > To Be Removed (Deprecations) ======= //
